:root{
  --loginFont: 'Source Code Pro', monospace;
  --spacing: 300px;
  --textColor: white;
  --homePageFont: 'Montserrat', sans-serif;
}

.space{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -9999;
}

.main{
  width: 100vw;
  color: white;
  z-index: 99;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0px auto;
  overscroll-behavior-y: contain;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  font-family: var(--homePageFont);

}



section {
  grid-column: 2 / 6;
  font-size: 1.25rem;
  line-height: 1.5;
  margin-bottom: var(--spacing);
  color: var(--textColor)
}

.main p{
  color: rgb(255, 255, 255);
  font-weight: 600;
}

.left {
  grid-column: 1 / 8;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
}
.right{
  grid-column: 6 / 12;
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
}

.center{
  margin-top: 30px;
  line-height: 300px;
  font-size: 22px;
  grid-column: 1 / 2;
  text-align: center;
  height: 400px;
  width:400px;
  border-radius: 50%;
  border:3px dotted #ffffff;
}
.center h1{
  font-weight: 600;
}
.resume {
  text-decoration: underline;
	text-decoration-color: rgb(0, 102, 255);
}

@media only screen and (max-width: 600px) and (-webkit-min-device-pixel-ratio:0){
  .center{
    height: 350px;
    width:350px;
    line-height: 275px;
    font-size: 18px;
    grid-column: 1 / 2;
  }
  .final{
    margin-top: -200px;
  }
  .left {
    grid-column: span 12;
    text-align: center;
  }
  .right{
    grid-column: span 12;
    text-align: center;
  }
}


.final{
  margin-top: -200px;
}
.link-image{
  height: 100px;
  margin-bottom:80px;
  grid-column: 5/5;
}
.github-image{
  height: 100px;
  grid-column: 5/5;
}





/* LOGIN */
.login{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}
.login form{
  resize: none;
  border-bottom: 2px solid black; 
}
.login input{
  resize: none;
  border: none;
  outline: none;
  font-family: var(--loginFont);
}
.login input:focus{
  outline: none;
}


